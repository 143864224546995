import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Office } from '../../../people/models';
import { OfficeContact } from '../../models/office-contact.model';

@Component({
  selector: 'app-contact-office',
  templateUrl: './contact-office.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactOfficeComponent implements OnInit {
  @Input() office: Office;
  officeAdress: OfficeContact;
  constructor() {}

  ngOnInit(): void {
    this.officeAdress = this.office.contacts.find(c => c.title === 'General inquiries');
    // console.log(this.officeAdress);
  }
}

<section class="contact-office">
  <dl class="contact-office__list mb-0">
    <dt class="mb-0 contact-office__office">
      <h1 class="h3 mb-0 contact-office__title">{{ office.name }}</h1>
      <p class="mb-0 contact-office__adress" *ngIf="office.address" [innerHTML]="office.address"></p>
      <app-office-contact-card *ngIf="officeAdress" [contact]="officeAdress"></app-office-contact-card>
    </dt>
    <dd class="mb-0 contact-office__contact">
      <ng-container *ngFor="let contact of office.contacts">
        <div class="contact-office__contact__wrapper" [ngClass]="'order--' + contact.title | urlRewrite">
          <app-office-contact-card [contact]="contact"></app-office-contact-card>
        </div>
      </ng-container>
    </dd>
  </dl>
</section>
